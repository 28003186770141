import { getCssText, reset as resetStitches } from '@iheartradio/web.companion';
import { RemixBrowser } from '@remix-run/react';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import React, {
  type ReactNode,
  startTransition,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { hydrateRoot } from 'react-dom/client';

import ClientStyleContext from './contexts/client-style';

interface ClientCacheProviderProps {
  children: ReactNode;
}

function ClientCacheProvider({ children }: ClientCacheProviderProps) {
  const [sheet, setSheet] = useState(getCssText());

  const reset = useCallback(() => {
    resetStitches();
    const css = getCssText();
    setSheet(css);
  }, []);

  return (
    <ClientStyleContext.Provider
      // memoize the value for this provider to minimize re-renders
      value={useMemo(() => ({ reset, sheet }), [reset, sheet])}
    >
      {children}
    </ClientStyleContext.Provider>
  );
}

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  whyDidYouRender(React);
}

const registerServiceWorker = async () => {
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  navigator.serviceWorker.register(
    `/service-worker.js?prefersDark=${prefersDark}`,
  );
  window.document.addEventListener('keydown', async event => {
    if (event.metaKey && event.key === 'k') {
      const { active } = await navigator.serviceWorker.ready;
      active?.postMessage('purge');
    }
  });
};

if ('serviceWorker' in navigator) {
  if (window.document.readyState === 'complete') {
    registerServiceWorker();
  } else {
    window.addEventListener('load', () => {
      registerServiceWorker();
    });
  }
}

startTransition(() => {
  hydrateRoot(
    document,
    <ClientCacheProvider>
      <RemixBrowser />
    </ClientCacheProvider>,
  );
});
